















































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";

export default Vue.extend({
  data() {
    return {
      util,
      userManager,
      user: userManager.getUserInfo(),
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: value => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "ID", value: "id" },
        { text: "Người nhận", value: "to_user_name" },
        { text: "Tiêu đề", value: "send_title" },
        { text: "Nội dung", value: "send_content" },
        { text: "Tiến độ", value: "progress" },
        { text: "Ngày khởi tạo", value: "created_at" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false
        }
      ],
      search: "",
      loading: false,
      options: {},
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      users: [],
      filterProgress: "",
      filterToUserId: "",
      availableProgressMap: {
        wait: "Chờ",
        seen: "Đã xem",
        replied: "Phản hồi",
        ht: "Hoàn thành",
        received: "Đã nhận"
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo hộp thư" : this.$t("EDIT");
    },
    availableProgresses() {
      const items = [];
      for (const k in this.availableProgressMap) {
        items.push({
          text: this.availableProgressMap[k],
          value: k
        });
      }
      return items;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  methods: {
    async initialize() {
      const filters = [];
      if (this.search) {
        filters.push({
          key: "id",
          operator: "=",
          value: this.search
        });
      }
      if (this.filterProgress) {
        filters.push({
          key: "progress",
          operator: "=",
          value: this.filterProgress
        });
      }
      if (this.filterToUserId) {
        filters.push({
          key: "to_user_id",
          operator: "=",
          value: this.filterToUserId
        });
      }
      this.loading = true;
      const { items, count } = await apiClient.conversationGetAll({
        options: this.options,
        filters
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.packageDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedIndex > -1) {
        result = await apiClient.conversationUpdate(this.editedItem);
      } else {
        result = await apiClient.conversationCreate(this.editedItem);
      }
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async receive(item) {
      if (
        await apiClient.conversationUpdate(
          {
            id: item.id,
            status: "Received"
          },
          ["status"]
        )
      ) {
        await this.initialize();
      }
    },
    async cancelReceive(item) {
      if (
        await apiClient.conversationUpdate(
          {
            id: item.id,
            status: ""
          },
          ["status"]
        )
      ) {
        await this.initialize();
      }
    }
  },
  async created() {
    const users = await apiClient.userGetAll({
      filters: []
    });
    for (const user of users.items) {
      this.users.push({
        text: user.name,
        value: user.id
      });
    }
  }
});
